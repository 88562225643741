@import "./../../scss/config";
$loader-transition: all 0.01s ease-in;
$hollowBoxSize_Loader: 3em;
$hollowLight_Loader: #ececec;
$hollowDark_Loader: $primary-color;
$hollowTiming_Loader: 1.25s;
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.6s ease-in;
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -10;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $loader-transition;
  &.active {
    z-index: 1000;
    opacity: 0.8;
    pointer-events: all;
    .loader,
    .circle {
      opacity: 1;
    }
  }
  .hollowLoader {
    width: $hollowBoxSize_Loader;
    height: $hollowBoxSize_Loader;
    animation: loaderAnim $hollowTiming_Loader infinite ease-in-out;
    -webkit-animation: loaderAnim $hollowTiming_Loader infinite ease-in-out;
    outline: 1px solid transparent;
    z-index: 1000;
    .largeBox {
      height: $hollowBoxSize_Loader;
      width: $hollowBoxSize_Loader;
      background-color: $hollowLight_Loader;
      outline: 1px solid transparent;
      position: fixed;
    }
    .smallBox {
      height: $hollowBoxSize_Loader;
      width: $hollowBoxSize_Loader;
      background-color: $hollowDark_Loader;
      position: fixed;
      z-index: 1;
      outline: 1px solid transparent;
      animation: smallBoxAnim $hollowTiming_Loader alternate infinite
        ease-in-out;
      -webkit-animation: smallBoxAnim $hollowTiming_Loader alternate infinite
        ease-in-out;
      transform: scale(0.75);
    }
  }

  @keyframes smallBoxAnim {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(0.75);
    }
  }

  @-webkit-keyframes smallBoxAnim {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(0.75);
    }
  }

  @keyframes loaderAnim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }

  @-webkit-keyframes loaderAnim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
}

#MessageInHandler {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -100%);

  transition: all 0.8s ease-in-out;
  cursor: pointer;
  z-index: 10000;
  &.active {
    transform: translate(0%, 0%);
  }
  .content {
    position: absolute;
    width: 100%;
    max-width: 900px;
    margin: auto;
    font-size: 1.2rem;
    background-color: #fff;
    border-radius: 4px;
    padding: 1rem;
    padding-bottom: 2rem;
    background-color: #fafafa;
    opacity: 0.98;
    color: #333;
    max-height: 90%;
    cursor: default;
    overflow: scroll;
  }

  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: gray;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  .title {
    font-size: 2.5rem;
    font-weight: 600;
    font-family: $font-family-1;
    @media (max-width: 575.98px) {
      font-size: 1.8rem;
    }
  }
  hr {
    width: 60px;
    color: $card-green;
    height: 4px;
    background-color: $card-green;

    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1.25rem;
  }
  @media (max-width: 575.98px) {
    padding: 1rem 12px;
    .content {
      font-size: 1rem;
    }
  }
}
