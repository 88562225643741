.tippy-box {
  background: #000000;
  color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
  padding: 6px 4px;
  max-width: 180px;
  font-size: 0.9rem;
  text-align: center;
}
