.OneAccordion {
  .accordion-btn {
    cursor: pointer;
  }

  .accordion-content-container {
    height: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;
  }
}
