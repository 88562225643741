.coming-soon {
  min-height: 100vh;
  background-color: $background-color-1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .img-logo {
    max-width: 500px;
    height: auto;
    @media (max-width: 575.98px) {
      max-width: 300px;
    }
  }
  .title {
    font-family: $font-family-1;
    font-size: 6rem;
    @media (max-width: 1199.98px) {
      font-size: 4.5rem;
    }
    @media (max-width: 575.98px) {
      font-size: 3.5rem;
    }
  }
  .text-part {
    font-weight: 600;
    font-size: 1.5rem;
    @media (max-width: 575.98px) {
      font-size: 1.1rem;
    }
  }

  .link {
    color: #fff;
    &:hover {
      color: $red-color;
    }
  }
  .background-img {
    position: absolute;
    max-height: 100%;
    width: auto;
    right: 0px;
  }
  .content-part {
    padding: 4rem 12px;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: none;
  }
}
