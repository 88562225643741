.law {
  padding-bottom: 8rem;
  padding-top: 4rem;
  background-image: linear-gradient(
      340deg,
      rgba(76, 76, 76, 0.02) 0%,
      rgba(76, 76, 76, 0.02) 34%,
      transparent 34%,
      transparent 67%,
      rgba(142, 142, 142, 0.02) 67%,
      rgba(142, 142, 142, 0.02) 73%,
      rgba(151, 151, 151, 0.02) 73%,
      rgba(151, 151, 151, 0.02) 100%
    ),
    linear-gradient(
      320deg,
      rgba(145, 145, 145, 0.02) 0%,
      rgba(145, 145, 145, 0.02) 10%,
      transparent 10%,
      transparent 72%,
      rgba(35, 35, 35, 0.02) 72%,
      rgba(35, 35, 35, 0.02) 76%,
      rgba(69, 69, 69, 0.02) 76%,
      rgba(69, 69, 69, 0.02) 100%
    ),
    linear-gradient(
      268deg,
      rgba(128, 128, 128, 0.02) 0%,
      rgba(128, 128, 128, 0.02) 5%,
      transparent 5%,
      transparent 76%,
      rgba(78, 78, 78, 0.02) 76%,
      rgba(78, 78, 78, 0.02) 83%,
      rgba(224, 224, 224, 0.02) 83%,
      rgba(224, 224, 224, 0.02) 100%
    ),
    linear-gradient(
      198deg,
      rgba(25, 25, 25, 0.02) 0%,
      rgba(25, 25, 25, 0.02) 36%,
      transparent 36%,
      transparent 85%,
      rgba(180, 180, 180, 0.02) 85%,
      rgba(180, 180, 180, 0.02) 99%,
      rgba(123, 123, 123, 0.02) 99%,
      rgba(123, 123, 123, 0.02) 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  h1 {
    // text-align: center;
    color: $primary-color;
    font-size: 3rem;
    font-weight: 600;
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    padding-top: 1rem;
  }

  p {
    margin: 0.2rem 0rem;
    padding: 0rem 0rem;
  }
  h3 {
    padding-top: 0.6rem;
    color: $primary-color;
  }
  a {
    text-decoration: underline;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 2px;
    background-color: $primary-color;
    margin-right: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }

  li,
  dd {
    padding-bottom: 0.2rem;
  }

  .right-col {
    padding-bottom: 2rem;
    .content {
      background-color: #fff;
      border-radius: 4px;
      padding: 1rem 12px;

      box-shadow: $main-box-shadow;
      ul {
        font-size: 0.9rem;
        color: $gray-color;
        .z-bold {
          color: #000000;
        }
      }
    }
  }
  .left-col {
    .content {
      border-radius: 4px;
      padding: 1rem 12px;
    }
  }
}
